


function lhd_grss(plhd,plscs) {

    const slhd = plhd

  
    let w_lscs =plscs.filter((item) => {
        if (item.sotr === slhd.sotr && item.god === slhd.god && item.mec === slhd.mec)
            return true
        else
            return false
    })

    let srows = w_lscs.map((ar) => {
        return ar.grs
    })
    return srows
}
export default lhd_grss;